@use 'assets/scss/mixins.scss' as mixins;

.cbc-form.room,
.Products {
    padding: 0 !important;
    margin-bottom: 159px;

    .form-group label {
        font-weight: 700;
    }

    h1 {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
    }

    .jumbotron {
        border-radius: 0;
        margin: 0 !important;
        padding: 15px !important;
        box-sizing: border-box;
        background-color: #e9ecef;
    }

    .jumbotron>div:last-child {
        margin-bottom: 0;
    }

    h2 {
        width: 100%;
        margin: 0;
        border-radius: 0 0 0 10px;
    }

    .wizard-form-container {
        padding: 0 15px;

        .tab-content {
            padding: 0 30px;
        }
    }

    .product-info {
        text-align: left;
        font-size: .85em;
        margin: 7px 40px;
    }

    .tab-content {
        .tab-pane {
            padding: 20px 0;
        }
    }

    .noInput {
        .form-group {
            margin: 0;

            label {
                margin: 0;
            }

            .text {
                font-size: .90em;
                color: #6B6F70;
            }
        }
    }

    .searchGroup-noMargin {
        margin-top: 0 !important;
    }

    .searchGroup {
        margin-top: 20px;

        .input-group {
            margin-bottom: 0 !important;

            input {
                border-right: 0;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }

            .input-group-append {
                @include mixins.theme('border-color', 'ui-primary-color');

                & {
                    border: 2px solid;
                    background: #fff;
                    border-left: 0;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }

                span {
                    background: #fff;
                    border: 0;
                }
            }
        }
    }

    .supplyDefault {
        padding-top: 50px;

        .more-info {
            top: -3px;
        }
    }

    .product-size-preview-container {
        position: relative;
        text-align: center;

        img {
            width: 68%;
        }

        .preiew-input {
            width: 50px;
            padding: 2px;
            margin: 0;
            border-width: 1px;
            font-size: .65em;
            font-weight: 600;
            text-align: center;
            height: auto;
            position: absolute;
        }
    }

    .room-form {
        hr {
            margin: 5px 0;
            @include mixins.theme('border-color', 'ui-secondary-color', 1);
        }
    }

    .gap-container {
        position: relative;

        img.arrow-image {
            position: absolute;
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        .gap-size-default-line {
            position: absolute;
            @include mixins.theme('background-color', 'ui-primary-icon-color');

            & {
                opacity: 0;
                transition: opacity 0.4s ease;
            }
        }
    }
}

.room-form {
    .modal-body {
        img {
            width: 100%;
        }
    }
}

@media (max-width: 695px) {

    .Products {
        margin-bottom: 0;
    }

    .room {
        .wizard-form-container {
            padding: 0;

            .tab-content {
                padding: 0;
            }
        }

        .product-info {
            margin: 7px 10px;
        }
    }

    .cbc-form.room .wizard-form-container {

        padding: 0;

        .searchGroup {
            margin-top: 0;
        }

        .product-info {
            margin: 7px 15px;
            text-align: center;
        }

        .tab-content {
            padding: 0;

            .tab-pane {
                padding: 0;
            }

        }

        .colorSelection {
            width: 115px;
            height: 115px;

            .selection-box {
                width: 115px;
                height: 115px;
            }
        }
    }

    .room-form {
        .input-group .input-group-append {
            font-size: .8em;
        }
    }
}